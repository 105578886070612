import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography, ListItemButton, List, Collapse } from '@mui/material';
import { Close as CloseIcon, ExpandLess, ExpandMore } from '@mui/icons-material';

import logo from "./../assets/languify_logo.svg";
import { MenuItems } from './Header';
import CustomButton from './CustomButton';


const useStyles = makeStyles(theme => ({
  sidebar: {
    position: 'fixed',
    width: "100%",
    height: '100vh',
    top: 0,
    right: 0,
    padding: theme.spacing(7, 5, 4, 5),
    backgroundColor: theme.palette.shades['clr-white-900'],
    boxShadow: '-0px 0 12px grey',
    zIndex: 100,
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(7.5, 5, 4, 5),
    },
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listContainer: {
    width: "100%",
    marginTop: theme.spacing(2.5),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: "50px",
  },
  logoContainer: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "28px",
    },
  },
  logo: {
    width: "140px",
    [theme.breakpoints.down("md")]: {
      width: "92px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "110px"
    },
  },
  closeIcon: {},
  listItemButton: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(8),
    '&.active': {
      color: '#1961BF',
    },
  },
  expandIcon: {
    color: '#1961BF',
  },
  normalExpandIcon: {
    color: 'inherit',
  },
  boxContainer: {
    padding: theme.spacing(1.5, 0),
  },
  studentElement: {
    width: "156px",
    height: "48px",
    borderRadius: "4px",
    border: `2px solid ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(8.5),
    marginLeft: theme.spacing(1),
  },
  anchorTag: {
    textDecoration: 'none',
  },
  listItem: {
    paddingLeft: theme.spacing(1)
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const combinedData = [
  { id: 'teach&Train', label: 'Teach & Train', category: 'Products', link: '/teach&train' },
  { id: 'inprep', label: 'In-prep pathway', category: 'Products', link: "/inprep" },
  { id: 'education', label: 'Education', category: 'Use Cases', link: '/usecase/education' },
  {
    id: 'interview', label: 'Interview Preparation', category: 'Use Cases',
    link: '/usecase/interview-preparation'
  },
];

function ProductItem({ item, handleClick, isActive }) {
  const classes = useStyles();

  return (
    <ListItemButton
      onClick={handleClick}
      className={`${classes.listItemButton} ${isActive ? 'active' : ''}`}
    >
      <Typography variant='h6-medium'>
        {item.label}
      </Typography>
    </ListItemButton>
  );
}

function Sidebar({ setSidebarOpen }) {
  const classes = useStyles();
  const [openProducts, setOpenProducts] = useState(false);
  const [openUseCases, setOpenUseCases] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const navigate = useNavigate();

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  }

  const handleClick = (item) => {
    setActiveItem(activeItem === item ? null : item);
    if (item.newPage) {
      return;
    }
    if (item.link) {
      navigate(item.link);
    }
  }

  return (
    <Box className={classes.sidebar}>
      <Box className={classes.header}>
        <Box className={classes.logoContainer}>
          <Link to="/business" style={{ textDecoration: "none" }}>
            <img src={logo} alt="languify" className={classes.logo} />
          </Link>
        </Box>
        <CloseIcon onClick={handleCloseSidebar} className={classes.closeIcon} />
      </Box>
      <List className={classes.listContainer}>
        <Box className={classes.boxContainer}>
          <ListItemButton onClick={() => setOpenProducts(!openProducts)}
            className={`${classes.listItem} ${openProducts ?
              classes.expandIcon : classes.normalExpandIcon}`}>
            <Typography variant='h6-semiBold'>
              Products
            </Typography>
            {openProducts ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {combinedData.filter(item => item.category === 'Products').map((item, index) => (
                <ProductItem
                  item={item}
                  key={index}
                  handleClick={() => handleClick(item)}
                  isActive={activeItem && activeItem.id === item.id}
                />
              ))}
            </List>
          </Collapse>
        </Box>
        <Box className={classes.boxContainer}>
          <ListItemButton onClick={() => setOpenUseCases(!openUseCases)}
            className={`${classes.listItem} ${openUseCases ?
              classes.expandIcon : classes.normalExpandIcon}`}>
            <Typography variant='h6-semiBold'>
              Use Cases
            </Typography>
            {openUseCases ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openUseCases} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {combinedData.filter(item => item.category === 'Use Cases').map((item, index) => (
                <ProductItem
                  item={item}
                  key={index}
                  handleClick={() => handleClick(item)}
                  isActive={activeItem && activeItem.id === item.id}
                />
              ))}
            </List>
          </Collapse>
        </Box>
        {MenuItems.map((option, index) => (
          <Box className={classes.boxContainer} key={index}>
            <ListItemButton
              onClick={() => handleClick(option)}
              className={classes.listItem}
            >
              {option.newPage ? (
                <a
                  href={option.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', width: "100%" }}
                >
                  <Typography color="shades.clr-black-900" variant='h6-semiBold'>
                    {option.title}
                  </Typography>
                </a>
              ) : (
                <Link to={option.link} style={{ textDecoration: 'none', width: "100%" }}>
                  <Typography color="shades.clr-black-900" variant='h6-semiBold'>
                    {option.title}
                  </Typography>
                </Link>
              )}
            </ListItemButton>
          </Box>
        ))}

      </List>

      <CustomButton
        size='small'
        variant='outlined'
        onClick={() => navigate('/')}
      >
        <Typography variant='h6-medium'>For students</Typography>
      </CustomButton>
    </Box>
  );
}

export default Sidebar;

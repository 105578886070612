import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { mockSteps } from './Constant';
import OverviewSteps from 'components/OverviewSteps';


const useStyles = makeStyles((theme) => ({
    container : {
        padding: theme.spacing(21.5, 37.5),
        backgroundColor : theme.palette.neutral['clr-50'],
        marginTop : theme.spacing(2),
        flexDirection : 'column',
        gap : theme.spacing(10),
        width : "100%",
        display : 'flex',
        justifyContent:'center',
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(14, 15),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(14, 10),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(14, 5),
        },
    }
}))

const B2cMockOverview = () => {
    const classes = useStyles();
    return (
      <Box id='interviewTypes' className={classes.container}>
        <Typography variant='h2-bold' color='b2c.dark-300'>
          Here’s How it works?
        </Typography>
          <OverviewSteps
            data={mockSteps} 
            subTitile='With Unlimited Reattempts'
            isTagsPresent={true}
          />
      </Box>
    )
}

export default B2cMockOverview
import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box, Typography } from '@mui/material';
import { useCase } from './Constant';
import { SlidingArrowButton } from 'components/CustomButton';


const useStyles = makeStyles(theme => ({
  parentContainer: {
    background: `
        url('https://assets.languify.in/images/b2cFrame.png') center center no-repeat,
        #2D3142`,
    backgroundSize: 'cover',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(14.5),
    padding: theme.spacing(15, 37.5),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(10, 15),
      gap: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(10, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(10, 5),
    },
  },
  container: {
    display: 'flex',
    gap: theme.spacing(5),
    justifyContent: 'space-between',
    [theme.breakpoints.down("lg")]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: theme.spacing(13)
    },
  },
  boxContainer: {
    borderRadius: theme.spacing(2),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#4E5661',
    padding: theme.spacing(3, 8),
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    minHeight: '150px',
    [theme.breakpoints.down("xl")]: {
      minHeight: '182px'
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: '100px'
    }
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    width: '50%',
    minWidth: '320px',

  },
  content: {
    fontFamily: 'Roboto Flex',
    fontWeight: 600,
    fontSize: '23px',
    lineHeight: '170%',
    letterSpacing: '0%',
    [theme.breakpoints.down("lg")]: {
      fontWeight: 600,
      fontSize: '16px',
    },
  }

}))

const UseCase = () => {
  const classes = useStyles();
  return (
    <Box className={classes.parentContainer}>
      <Typography variant='h2-bold' color='neutral.clr-400'>Who can use this?</Typography>
      <Box className={classes.container}>
        {useCase.map((data, index) => (
          <Box key={index} className={classes.box}>
            <Box className={classes.boxContainer}>
              <Typography color='b2c.light-900' className={classes.content}>
                {data.content}
              </Typography>
            </Box>
            <img src={data.img} alt='use case' />
          </Box>
        ))}
      </Box>
      <Box display='flex' flexDirection='column' gap='40px' alignItems='center'>
        <Typography variant='h2-bold' color='b2c.light-600' style={{ textAlign: 'center' }}>
          Get Interview Ready in 60 Minutes!
        </Typography>
        <SlidingArrowButton
          label="Start Preparing Now"
          bgColor='#1961BF'
          variant="outlined"
          width="291px"
          sx={{ textTransform: 'none' }}
          onClick={() => {
            const element = document.getElementById("pricing");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
        />
      </Box>
    </Box>
  )
}

export default UseCase
import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box, Typography } from '@mui/material';
import logo from '../../assets/languify_logo_white.svg'
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';

const useStyles = makeStyles(theme => ({
  parentContainer: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    padding: theme.spacing(15, 37.5),
    background: `linear-gradient(180deg, #2D3142 0%, #737DA8 100%)`,
    justifyContent: "space-around",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(10, 15),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(10, 10),
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(10, 5),
    },
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "8px",
      background: `linear-gradient(284.04deg,#BE93C5 0%,#7BC6CC 100%),
                    linear-gradient(0deg,#FFFFFF,#FFFFFF)`,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down("lg")]: {
      flexDirection: 'column',
      gap: theme.spacing(10)
    },
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.5),
    marginLeft: theme.spacing(1),
    maxWidth: "297px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: '100%'
    },
  },
  element: {
    textDecoration: "none",
    position: "relative",
    overflow: "hidden",
    height: "1.5em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    '&:hover $hoverText': {
      transform: 'translateY(0)',
      whiteSpace: "nowrap"
    },
    '&:hover $normalText': {
      transform: 'translateY(-100%)',
      whiteSpace: "nowrap"
    },
  },
  normalText: {
    display: "block",
    opacity: 0.75,
    transition: "transform 0.1s ease-in-out",
    color: theme.palette.shades['clr-white-900'],
    ...theme.typography['body01-semiBold'],
  },
  hoverText: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    transition: "transform 0.1s ease-in-out",
    transform: "translateY(100%)",
    color: theme.palette.shades['clr-white-900'],
    ...theme.typography['body01-semiBold'],
  },
  bottomContainer: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  socialContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: '142px',
  },
  socialBox: {
    width: "36.75px",
    height: "35px",
    borderRadius: "50%",
    border: "1.5px solid rgba(255, 255, 255, 0.25)",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    color: theme.palette.shades['clr-white-900'],
    cursor: "pointer",
    "&:hover": {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.4)'
    },
  },
  socialIcon: {
    width: "12.46px",
    height: "11.87px",
    margin: "auto",
  },
  b2cElements: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3.25),
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  circle: {
    width: "4px",
    height: "4px",
    borderRadius: '50%',
    backgroundColor: theme.palette.shades['clr-white-900'],
    margin: theme.spacing(0, 2),
    opacity: 0.75
  },
}))

const contact = [
  {
    id: 1,
    name: "growth@languify.in",
    link: "mailto:growth@languify.in",
    newPage: true
  },
  {
    id: 2,
    name: "+91-9589952975",
    link: "https://wa.me/919589952975",
    newPage: true
  }
];

const linkArray = [
  { href: `${process.env.REACT_APP_ASSETS_URL}/pdf/terms-%26-conditions.pdf`, text: 'Terms & Conditions' },
  { href: `${process.env.REACT_APP_ASSETS_URL}/pdf/languify-refund-policy-v2.pdf`, text: 'Refund Policy' },
  { href: `${process.env.REACT_APP_ASSETS_URL}/pdf/languify-privacy-policy.pdf`, text: 'Privacy' },
];

const socialLinks = [
  {
    href: `${process.env.REACT_APP_ASSETS_URL}/images/footer_Linkdin.svg`,
    link: "https://www.linkedin.com/company/languify-in/"
  },
  {
    href: `${process.env.REACT_APP_ASSETS_URL}/images/footer_facebook.svg`,
    link: "https://www.facebook.com/Languify/"
  },
];


const B2cFooter = () => {
  const classes = useStyles();
  return (
    <Box className={classes.parentContainer}>
      <Box display='flex' flexDirection='column' gap='84px' width='100%'>
        <Link to="/b2c" style={{ textDecoration: "none" }}>
          <img
            src={logo}
            alt="languify" className={classes.logo}
          />
        </Link>
        <Box className={classes.container}>
          <Box className={`${classes.leftSection}`}>
            <Typography variant='h6-semiBold' color='shades.clr-white-900'>About Languify</Typography>
            <Typography color="b2c.light-600" variant='body01-medium'>
              We are a team of highly motivated folks from global universities and various domain
              expertise. We collaborate and co-create at Languify to solve the communication,
              assessment and hiring challenges of India and the World.
            </Typography>
          </Box>
          <Box className={`${classes.leftSection}`}>
            <Typography variant='h6-semiBold' color='shades.clr-white-900'>Our offices</Typography>
            <Typography color="b2c.light-600" variant='body01-medium'>
              Jr Mig 86, Saket Nagar Rani Laxmi Bai Park Huzur, Bhopal, Madhya Pradesh,
              India Pin code: 462024
            </Typography>
            <Typography color="b2c.light-600" variant='body01-medium'>
              202, Shubh Enclave Layout, Opp Fisherman's Wharf, 1st Avenue, 5th Main Road,
              Ambalipura, Harlur, Bengaluru, Karnatak, India Pin code: 560102
            </Typography>
          </Box>
          <Box className={`${classes.leftSection}`}>
            <Typography variant='h6-semiBold' color='shades.clr-white-900'>Contact Us</Typography>
            {contact.map((link, index) => (
              <a
                className={classes.element}
                href={link.link}
                key={link.id}
                target="_blank" rel="noreferrer"
              >
                <Typography className={classes.normalText}> {link.name}</Typography>
                <Typography className={classes.hoverText}> {link.name}</Typography>
              </a>
            ))}
          </Box>
        </Box>
        <Box>
          <Box className={classes.bottomContainer}>
            <Box className={classes.b2cElements}>
              {linkArray.map((link, index) => (
                <React.Fragment key={index}>
                  <a className={classes.element} href={link?.href} target="_blank" rel="noreferrer">
                    <Typography className={classes.normalText}  >
                      {link.text}
                    </Typography>
                    <Typography variant="body01-semiBold" className={classes.hoverText} >
                      {link.text}
                    </Typography>
                  </a>
                  {index < linkArray.length - 1 && <div className={classes.circle}></div>}
                </React.Fragment>
              ))}
            </Box>
            <Box className={classes.socialContainer}>
              {socialLinks.map((socialLink) => (
                <a href={socialLink.link} target="_blank" rel="noreferrer">
                  <Box className={classes.socialBox}>
                    <img src={socialLink.href} alt='icon' className={classes.socialIcon} />
                  </Box>
                </a>
              ))}
              <a href='https://www.instagram.com/languify_ai/?igsh=ODY4MWd3ZzI2aWhx#' target="_blank" rel="noreferrer">
                <Box className={classes.socialBox}>
                  <InstagramIcon fontSize="xl" />
                </Box>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}

export default B2cFooter
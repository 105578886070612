import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between",
    width : '100%',
    gap: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
     flexWrap : 'wrap',
     justifyContent:'center'
    },
  },
  tagsContainer: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap : 'wrap'
  },
  tag: {
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(1),
    border : `1px solid ${theme.palette.primary['clr-500']}`,
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeTag: {
    backgroundColor: theme.palette.primary["clr-500"],
    color: theme.palette.shades["clr-white-900"],
  },
  inactiveTag: {
    backgroundColor: theme.palette.shades["clr-white-900"],
    color: theme.palette.primary["clr-500"],
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(8),
    width: "100%",
    maxWidth: "637px",
    [theme.breakpoints.down("md")]: {
      maxWidth : "100%"
     },
  },
  stepsBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "color 0.3s",
    padding : theme.spacing(3,1)
  },
  activeStep: {
    color: theme.palette.primary["clr-500"],
  },
  inactiveStep: {
    color: theme.palette.neutral["clr-600"],
  },
  imgContainer : {
    width : '463px',
    height : '463px',
    minWidth : '463px',
    border : `1px solid #BDC3C7`,
    borderRadius : theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      width : '420px',
      height : '420px',
      minWidth : '420px',
     },
    [theme.breakpoints.down("sm")]: {
      width : '320px',
      height : '320px',
      minWidth : '320px',
     },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain", 
    objectPosition: "center", 
    borderRadius : theme.spacing(10)
  },
}));

const OverviewSteps = ({data , subTitile = '', isTagsPresent = false}) => {
  const classes = useStyles();
  const [activeTagIndex, setActiveTagIndex] = useState(0); 
  const [activeStepIndex, setActiveStepIndex] = useState(0); 

  const activeTag = data[activeTagIndex];
  const activeSteps = activeTag.steps;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStepIndex((prevStepIndex) => {
        if (prevStepIndex < activeSteps.length - 1) {
          return prevStepIndex + 1;
        } else {
          setActiveTagIndex((prevTagIndex) => (prevTagIndex + 1) % data.length);
          return 0;
        }
      });
    }, 2000);

    return () => clearInterval(interval); 
  }, [activeSteps]);

  const handleTagClick = (index) => {
    setActiveTagIndex(index);
    setActiveStepIndex(0);
  };

  const handleStepClick = (index) => {
    setActiveStepIndex(index);
  };

  const activeImage = activeSteps[activeStepIndex]?.img;

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" gap="16px">
        <Box className={classes.tagsContainer}>
          {isTagsPresent && data.map((item, index) => (
            <Box
              key={item.name}
              className={`${classes.tag} ${
                activeTagIndex === index ? classes.activeTag : classes.inactiveTag
              }`}
              onClick={() => handleTagClick(index)}
            >
              <Typography variant="overline-bold">{item.name}</Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.stepsContainer}>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h2-bold" color="neutral.clr-800">
              {activeTag.title}
            </Typography>
            <Typography variant="h6-bold" color="neutral.clr-400">
              {subTitile}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
           {isTagsPresent &&  <Typography variant="h6-bold" color="neutral.clr-700">
              Easy Steps:
            </Typography>}
            <Box display="flex" flexDirection="column">
              {activeSteps.map((step, index) => (
                <React.Fragment key={index}>
                  <Box
                    className={`${classes.stepsBox} ${
                      activeStepIndex === index
                        ? classes.activeStep
                        : classes.inactiveStep
                    }`}
                    onClick={() => handleStepClick(index)}
                  >
                    <Typography variant="h5-medium">
                      {activeStepIndex === index ? `-> ${step.title}` : step.title}
                    </Typography>
                  </Box>
                  {index < activeSteps.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.imgContainer}>
        {activeImage && <img  src={activeImage} alt="step" className={classes.img} />}
      </Box>
    </Box>
  );
};

export default OverviewSteps;
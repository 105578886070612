import React from 'react';
import { makeStyles } from "@mui/styles";
import { Button, Link, useTheme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from "./../assets/languify_logo_foot.svg";
import InstagramIcon from '@mui/icons-material/Instagram';

const products = [
  {
    id: 1,
    name: "Teach & Train",
    link: "/teach&train",
    newPage: false
  },
  {
    id: 2,
    name: "In-prep",
    link: "/inprep",
    newPage: false
  },

];

const company = [
  {
    id: 1,
    name: "About Us",
    link: "/aboutus",
    newPage: false
  },
  {
    id: 2,
    name: "Careers",
    link: process.env.REACT_APP_CAREER_PAGE_LINK,
    newPage: true
  },
];

const contact = [
  {
    id: 1,
    name: "growth@languify.in",
    link: "mailto:growth@languify.in",
    newPage: true
  },
  {
    id: 2,
    name: "+91-9589952975",
    link: "https://wa.me/919589952975",
    newPage: true
  }
];

const socialLinks = [
  {
    href: `${process.env.REACT_APP_ASSETS_URL}/images/footer_Linkdin.svg`,
    link: "https://www.linkedin.com/company/languify-in/"
  },
  {
    href: `${process.env.REACT_APP_ASSETS_URL}/images/footer_facebook.svg`,
    link: "https://www.facebook.com/Languify/"
  },
];

const linkArray = [
  { href: `${process.env.REACT_APP_ASSETS_URL}/pdf/terms-%26-conditions.pdf`, text: 'Terms & Conditions' },
  { href: `${process.env.REACT_APP_ASSETS_URL}/pdf/languify-refund-policy-v2.pdf`, text: 'Refund Policy' },
  { href: `${process.env.REACT_APP_ASSETS_URL}/pdf/languify-privacy-policy.pdf`, text: 'Privacy' },
];

const useStyles = makeStyles(theme => ({
  parentContainer: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    padding: theme.spacing(0, 37.5),
    background: `linear-gradient(278.27deg,#4B6CB7 0%,#002759 112.69%),
              linear-gradient(0deg,#FFFFFF,#FFFFFF)`,
    justifyContent: "space-around",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 15),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(0, 5),
    },
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "8px",
      background: `linear-gradient(284.04deg,#BE93C5 0%,#7BC6CC 100%),
                linear-gradient(0deg,#FFFFFF,#FFFFFF)`,
    },
  },
  childContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(10, 0),
    justifyContent: "space-between",
    gap: theme.spacing(5),
    [theme.breakpoints.down("1340")]: {
      padding: theme.spacing(10, 0),
    },
  },
  b2cChildContainer: {
    padding: theme.spacing(15, 0),
    gap: theme.spacing(21),
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  subFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(15),
    },
  },
  opacity: {
    opacity: 0.75
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(9),
    marginLeft: theme.spacing(1),
    maxWidth: "297px",
  },
  leftSectonB2c: {
    maxWidth: '867px',
    width: "100%",
  },
  itemContainer: {
    maxWidth: '100%',
    display: "flex",
    gap: theme.spacing(3),
    flexDirection: "column",
    [theme.breakpoints.between("md", "1340")]: {
      maxWidth: "unset",
      width: "100%",
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "unset",
      marginBottom: theme.spacing(7.5),
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
      padding: 0
    },
    [theme.breakpoints.down("700")]: {
      marginBottom: 0
    },
  },
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    width: "270px",

  },
  footerButtonLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    border: `1px solid ${theme.palette.shades['clr-white-900']}`,
    borderRadius: 0,
    width: "100%",
  },
  footerButtonRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "none",
    width: "100%",
    borderRadius: '0 4px 4px 0',
    minHeight: "40px",
    backgroundColor: theme.palette.shades['clr-white-900'],
    borderColor: theme.palette.shades['clr-white-900'],
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50'],
      borderColor: theme.palette.neutral['clr-50'],
    },
  },
  bookCall: {
    textDecoration: "none",
    ...theme.typography['h6-medium'],
    color: theme.palette.primary.main,
  },
  items: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(8),
  },
  elements: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(3),
    gap: theme.spacing(3.25),
    alignItems: "center",
    [theme.breakpoints.down("1330")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      gap: 0,
    },
  },
  b2cElements: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3.25),
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  elementsBot: {
    display: "none",
    [theme.breakpoints.down("700")]: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(3.25),
      alignItems: "center",
      marginTop: theme.spacing(7),
    },
  },
  element: {
    textDecoration: "none",
    position: "relative",
    overflow: "hidden",
    height: "1.5em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    '&:hover $hoverText': {
      transform: 'translateY(0)',
      whiteSpace: "nowrap"
    },
    '&:hover $normalText': {
      transform: 'translateY(-100%)',
      whiteSpace: "nowrap"
    },
  },
  normalText: {
    display: "block",
    opacity: 0.75,
    transition: "transform 0.1s ease-in-out",
    color: theme.palette.shades['clr-white-900'],
    ...theme.typography['body01-semiBold'],
  },
  hoverText: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    transition: "transform 0.1s ease-in-out",
    transform: "translateY(100%)",
    color: theme.palette.shades['clr-white-900'],
    ...theme.typography['body01-semiBold'],
  },
  circle: {
    width: "4px",
    height: "4px",
    borderRadius: '50%',
    backgroundColor: theme.palette.shades['clr-white-900'],
    margin: theme.spacing(0, 2),
    opacity: 0.75
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      gap: theme.spacing(11),
    },
    [theme.breakpoints.between("700", "1340")]: {
      justifyContent: "space-between",
      height: "auto"
    },
    [theme.breakpoints.down("700")]: {
      flexDirection: "column",
      gap: theme.spacing(5),
    },
  },
  linksitems: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "173px",
  },
  linksBox: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(14.25),
  },
  socialContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: '142px',
  },
  socialContainerTab: {
    display: "none",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: '142px',
    width: "100%",
    marginTop: theme.spacing(3),
    [theme.breakpoints.between("700", "1330")]: {
      display: "flex",
      gap: theme.spacing(16),
      position: "relative"
    }
  },
  socialContainerBot: {
    display: "none",
    [theme.breakpoints.down("700")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: '142px',
    }
  },
  socialBox: {
    width: "36.75px",
    height: "35px",
    borderRadius: "50%",
    border: "1.5px solid rgba(255, 255, 255, 0.25)",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    color: theme.palette.shades['clr-white-900'],
    cursor: "pointer",
    "&:hover": {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.4)'
    },
  },
  socialIcon: {
    width: "12.46px",
    height: "11.87px",
    margin: "auto",
  },
  botFooter: {
    [theme.breakpoints.down("700")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(18),
      gap: theme.spacing(7),
    },
  },
  talkContainer: {
    display: "flex",
    flexDirection: "column",
    width: "270px",
    height: "175px",
    borderRadius: "24px",
    border: `1px dashed`,
    borderColor: theme.palette.shades['clr-white-900']
  },
  talkText: {
    minHeight: "64px",
    borderBottom: `1px dashed ${theme.palette.shades['clr-white-900']}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bookContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  callContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: theme.spacing(5),
  },
  mobileElement: {
    display: "none",
    [theme.breakpoints.down('md')]: { display: "block", }
  },
  button: {
    borderRadius: "4px", margin: theme.spacing(2.5, 0)
  },
  textDecoration: {
    textDecoration: "none"
  },
  elementsTab: {
    display: "none",
    flexDirection: "row",
    gap: theme.spacing(3.25),
    alignItems: "center",
    [theme.breakpoints.between("sm", "1330")]: {
      display: "flex",
      height: 35
    },

  },
  socialBoxTab: {
    display: "none",
    alignSelf: "center",
    [theme.breakpoints.between("sm", "1330")]: {
      display: "flex",
      gap: theme.spacing(3),
      position: "absolute",
      width: "60%",
      left: "20%",
      alignItems: "center",
      justifyContent: "center",
      top: 0
    },
  },
  b2csocialBox: {
    display: "flex",
    gap: theme.spacing(4),
  },
  b2cContainer: {
    maxWidth: "867px",
    width: "100%",
  },
  b2cFooter: {
    alignItems: "flex-start",
    gap: theme.spacing(10),
    marginBottom: 0,
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },
  b2clinkContainer: {
    height: "auto",
    width: "auto"
  },
  b2cLink: {
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(4)
    }
  },
  itemsRight: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(8),
  },
  address: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    maxWidth: '437px',
  },
  bottomContainer: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    }
  }
}));


function Footer({ isB2c = false }) {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate(link);
  }

  return (
    <Box className={classes.parentContainer}>
      <Box className={`${classes.childContainer} ${isB2c ? classes.b2cChildContainer : ""}`}>
        <Box className={classes.logoContainer}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src={logo}
              alt="languify" className={classes.logo}
            />
          </Link>
        </Box>
        <Box className={`${classes.subFooter} ${isB2c ? classes.b2cFooter : ""}`}>
          <Box className={`${classes.leftSection} ${isB2c ? classes.leftSectonB2c : ""}`}>
            <Box className={`${classes.itemContainer}`}>
              <Typography variant='h6-semiBold' color='shades.clr-white-900'>About Languify</Typography>
              <Typography color="shades.clr-white-900" variant='body01-medium'
                className={classes.opacity}>
                We are a team of highly motivated folks from global universities and various domain
                expertise. We collaborate and co-create at Languify to solve the communication,
                assessment and hiring challenges of India and the World.
              </Typography>
            </Box>
            {!isB2c && (
              <>
                <Box className={classes.footerButtons}>
                  <Box className={classes.footerButtonLeft}>
                    <Typography variant='h6-bold' color={theme.palette.shades['clr-white-900']}>
                      Let’s Talk
                    </Typography>
                  </Box>
                  <Button className={classes.footerButtonRight}>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL}
                      className={classes.bookCall}
                      variant='h6-medium'>
                      Book a call
                    </a>
                  </Button>
                </Box>
                <a
                  href="https://www.producthunt.com/posts/languify?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-languify"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=409722&theme=light&period=daily"
                    alt="Languify - Make&#0032;every&#0032;student&#0032;&#0038;&#0032;teacher&#0032;feel&#0032;like&#0032;a&#0032;superhero | Product Hunt"
                    style={{ width: '100%', height: '100%' }}
                  />
                </a>
              </>
            )}
          </Box>
          {!isB2c && (
            <Box className={`${classes.linkContainer} ${isB2c ? classes.b2clinkContainer : ""}`}>
              <Box className={classes.items}>
                <Box className={classes.linksitems}>
                  <Typography variant='h6-semiBold' color='shades.clr-white-900'>Products</Typography>
                  {products.map((link) => (
                    link.newPage ? (
                      <a
                        className={classes.element}
                        href={link.link}
                        key={link.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography className={classes.normalText}>{link.name}</Typography>
                        <Typography className={classes.hoverText}>{link.name}</Typography>
                      </a>
                    ) : (
                      <Link
                        className={classes.element}
                        to={link.link}
                        key={link.id}
                        onClick={() => handleNavigate(link.link)}
                      >
                        <Typography className={classes.normalText}>{link.name}</Typography>
                        <Typography className={classes.hoverText}>{link.name}</Typography>
                      </Link>
                    )
                  ))}
                </Box>
                <Box className={classes.linksitems}>
                  <Typography variant='h6-semiBold' color='shades.clr-white-900'>Company</Typography>
                  {company.map((link) => (
                    link.newPage ? (
                      <a
                        className={classes.element}
                        href={link.link}
                        key={link.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography className={classes.normalText}>{link.name}</Typography>
                        <Typography className={classes.hoverText}>{link.name}</Typography>
                      </a>
                    ) : (
                      <Link
                        className={classes.element}
                        to={link.link}
                        key={link.id}
                        onClick={() => handleNavigate(link.link)}
                      >
                        <Typography className={classes.normalText}>{link.name}</Typography>
                        <Typography className={classes.hoverText}>{link.name}</Typography>
                      </Link>
                    )
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box className={classes.itemsRight}>
            <Box className={classes.linksitems}>
              <Typography variant='h6-semiBold' color='shades.clr-white-900'>Contact Us</Typography>
              {contact.map((link, index) => (
                <a
                  className={classes.element}
                  href={link.link}
                  key={link.id}
                  target="_blank" rel="noreferrer"
                >
                  <Typography className={classes.normalText}> {link.name}</Typography>
                  <Typography className={classes.hoverText}> {link.name}</Typography>
                </a>
              ))}
            </Box>
            {!isB2c && (
              <>
                <Box className={classes.address}>
                  <Typography variant='h6-semiBold' color='shades.clr-white-900'>Our offices</Typography>
                  <Typography className={classes.normalText}>
                    Jr Mig 86, Saket Nagar Rani Laxmi Bai Park Huzur, Bhopal, Madhya Pradesh,
                    India Pin code: 462024
                  </Typography>
                  <Typography className={classes.normalText}>
                    202, Shubh Enclave Layout, Opp Fisherman's Wharf, 1st Avenue, 5th Main Road,
                    Ambalipura, Harlur, Bengaluru, Karnatak, India Pin code: 560102
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {!isB2c &&
          <Box className={classes.bottomContainer}>
            <Box className={classes.socialContainer}>
              {socialLinks.map((socialLink) => (
                <Link href={socialLink.link} target="_blank" rel="noreferrer">
                  <Box className={classes.socialBox}>
                    <img src={socialLink.href} alt='icon' className={classes.socialIcon} />
                  </Box>
                </Link>
              ))}
              <Link href='https://www.instagram.com/thelanguify/' target="_blank" rel="noreferrer">
                <Box className={classes.socialBox}>
                  <InstagramIcon fontSize="xl" />
                </Box>
              </Link>
            </Box>
            <Box className={classes.b2cElements}>
              {linkArray.map((link, index) => (
                <React.Fragment key={index}>
                  <a className={classes.element} href={link.href} target="_blank" rel="noreferrer">
                    <Typography className={classes.normalText}  >
                      {link.text}
                    </Typography>
                    <Typography variant="body01-semiBold" className={classes.hoverText} >
                      {link.text}
                    </Typography>
                  </a>
                  {index < linkArray.length - 1 && <div className={classes.circle}></div>}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        }
        {isB2c &&
          <Box className={classes.b2cLink}>
            <Box className={classes.b2cElements}>
              {linkArray.map((link, index) => (
                <React.Fragment key={index}>
                  <a className={classes.element} href={link.href} target="_blank" rel="noreferrer">
                    <Typography className={classes.normalText}  >
                      {link.text}
                    </Typography>
                    <Typography variant="body01-semiBold" className={classes.hoverText} >
                      {link.text}
                    </Typography>
                  </a>
                  {index < linkArray.length - 1 && <div className={classes.circle}></div>}
                </React.Fragment>
              ))}
            </Box>
            <Box className={classes.b2csocialBox}>
              {socialLinks.map((socialLink) => (
                <Link href={socialLink.link} target="_blank" rel="noreferrer">
                  <Box className={classes.socialBox}>
                    <img src={socialLink.href} alt='icon' className={classes.socialIcon} />
                  </Box>
                </Link>
              ))}
              <Link
                href='https://www.instagram.com/thelanguify/'
                target="_blank"
                rel="noreferrer"
              >
                <Box className={classes.socialBox}>
                  <InstagramIcon fontSize="xl" />
                </Box>
              </Link>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default Footer;
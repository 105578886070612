import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import ClientCarousel from 'components/Carousel';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(10),
    width: '70%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10, 10),
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 5),
      width: '100%',
    },
  },
  dottedFrame: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  smallDottedFrame: {
    height: '303px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  pattern1: {
    position: 'absolute',
    left: -100,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  pattern2: {
    position: 'absolute',
    right: -100,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const B2cCompanyTags = ({ showTrustByStudents = false, topItems = [], bottomItems = [] }) => {
  const classes = useStyles();

  return (
    <Box display='flex' width='100%' justifyContent='space-between'>
      <img
        src='https://assets.languify.in/images/b2cDottedFrame.png'
        alt='dots'
        className={showTrustByStudents ? classes.smallDottedFrame : classes.dottedFrame}
      />
      <Box className={classes.container}>
        <Box display="flex" flexDirection="column" gap="16px" alignItems="center">
          <Box position='relative'>
            {showTrustByStudents && (
              <img
                src='https://assets.languify.in/images/b2cpattern3.png'
                alt='pattern'
                className={classes.pattern1}
              />
            )}
            <Typography variant="h2-bold" sx={{ textAlign: 'center' }}>
              {showTrustByStudents ? 'Trusted by students from' : 'Interview Wizard: Your Career Launchpad'}
            </Typography>
            {showTrustByStudents && (
              <img
                src='https://assets.languify.in/images/b2cpattern4.png'
                alt='pattern'
                className={classes.pattern2}
              />
            )}
          </Box>
          {!showTrustByStudents &&
            <Typography variant="h5-medium" color="b2c.dark-300" sx={{ textAlign: 'center' }}>
              Prepare for interviews at MNCs, startups, mid-sized firms & beyond
            </Typography>}
        </Box>

        <Box display='flex' flexDirection='column' gap='12px' width='100%' position='relative'>
          <ClientCarousel images={topItems}  wideSpacing={showTrustByStudents ? true : false}/>
          {!showTrustByStudents && (
            <ClientCarousel images={bottomItems} direction='right'/>
          )}
        </Box>

      </Box>
      <img
        src='https://assets.languify.in/images/b2cDottedFrame.png'
        alt='dots'
        className={showTrustByStudents ? classes.smallDottedFrame : classes.dottedFrame}
      />
    </Box>
  );
};

export default B2cCompanyTags;

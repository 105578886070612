import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { animation } from "../utils/index"
import { trackCustomEvent } from "utils";
import { HoverButton, IconButton } from "./CustomButton";

const useStyles = makeStyles((theme) => ({
    box: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(8),
        padding: theme.spacing(16, 50),
        alignItems: "center",
        position: "relative",
        opacity: 0,
        transform: "translateY(50px)",
        transition: "all 500ms ease",
        [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(16, 37.5),
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(16, 15),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(16, 10),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 5),
        }
    },
    tiles: {
        position: "absolute",
        top: 0,
        [theme.breakpoints.down('1300')]: {
            width: 280
        },
        [theme.breakpoints.down('lg')]: {
            width: 250
        },
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    tilesLeft: {
        left: 0,
        opacity: 0,
        transform: "translateX(-50px)",
        transition: "all 500ms ease",
    },
    tilesRight: {
        right: 0,
        opacity: 0,
        transform: "translateX(50px)",
        transition: "all 500ms ease",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        overflow: "hidden",
        background: theme.palette.secondary['clr-50'],
        border: "1px solid",
        borderColor: theme.palette.secondary['clr-800'],
        borderRadius: 12,
        padding: theme.spacing(10),
        gap: theme.spacing(16, 12),
        [theme.breakpoints.down("md")]: {
            gap: theme.spacing(8),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            borderRadius: theme.spacing(2),
            padding: theme.spacing(4),
        },
    },
    heading: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: theme.spacing(2)
    },
    headingContainer: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(3)
    },
    gradientText: {
        background: 'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        zIndex: 2,
    },
    video: {
        width: "80%",
        [theme.breakpoints.down("md")]: {
            margin: "auto"
        },
    },
    mainContainer: {
        display: 'flex', width: '100%', height: 'fit-content',
        justifyContent: 'space-between',
        zIndex: 2,
        [theme.breakpoints.up('xl')]: {
            alignItems: "center"
        },
        [theme.breakpoints.down('md')]: {
            width: '100%', borderRadius: 0,
            gap: theme.spacing(8),
            flexDirection: "column-reverse",
        },
    },
    description: {
        display: 'flex',
        alignItems: "start",
        gap: theme.spacing(1)
    },
    contentContainer: {
        width: '50%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        gap: theme.spacing(2),
        [theme.breakpoints.up('xl')]: {
            maxHeight: 208,
            gap: theme.spacing(8)
        },
        [theme.breakpoints.down('lg')]: {
            gap: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            gap: theme.spacing(4)
        }
    },
    about: {
        textAlign: "center"
    },
    videoContainer: {
        width: '45%',
        display: "flex",
        [theme.breakpoints.down('md')]: {
            width: '100%',
            display: "flex",
            alignItems: "center"
        }
    },
    videoContainerReverse: {
        flexDirection: "row-reverse",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column-reverse"
        }
    },
    toolText: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    tryButton: {
        zIndex: 4,
        alignSelf: "end",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            width: "100%",
            justifyContent: "center"
        }
    },
    background: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: -5,
        zIndex: 1,
        width: "100%"
    },
    bottomText: {
        textAlign: "center",
        width: "80%",
        margin: "auto",
    },
    displayNone: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    enterFromX: {
        opacity: 1,
        transform: "translateX(0px)",
    },
    enterFromY: {
        opacity: 1,
        transform: "translateY(0px)",
    },
    button: {
        background: "linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)",
        display: "flex",
        alignItems: "center",
        border: "1px solid",
        cursor: "pointer",
        borderColor: theme.palette.primary.main,
        borderRadius: 8,
        padding: theme.spacing(3, 22),
        textTransform: "none",
    },
    connectButton: {
        background: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 8,
        cursor: "pointer",
        padding: theme.spacing(3, 12),
    },
    textDecoration: {
        textDecoration: "none",
    },
    callContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        marginTop: theme.spacing(5),
    },
}));

export default function ProductBreakdowns({ onExperienceLive, Steps }) {
    const classes = useStyles();

    const handleLinkClick = () => {
        trackCustomEvent('get_in_touch_inprep');
    }

    React.useEffect(() => {
        const handleScroll = () => {
            animation(`.${classes.tilesLeft}`, `${classes.enterFromX}`);
            animation(`.${classes.tilesRight}`, `${classes.enterFromX}`);
            animation(`.${classes.box}`, `${classes.enterFromY}`, 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <Box className={classes.box}>
            <img
                className={`${classes.tiles} ${classes.tilesLeft}`}
                src={`${process.env.REACT_APP_ASSETS_URL}/images/t&t_dotted_frame.png`}
                alt="Frame"
            />
            <img
                className={`${classes.tiles} ${classes.tilesRight}`}
                src={`${process.env.REACT_APP_ASSETS_URL}/images/t&t_dotted_frame.png`}
                alt="Frame"
            />
            <Box className={classes.heading}>
                <Typography className={classes.gradientText} variant="h2-medium">
                    Your personal teacher assistant
                </Typography>
                <Typography variant="h4-medium" color="success.main">
                    Practice . Feedback . Improve
                </Typography>
            </Box>
            {
                Steps.map((step, index) => (
                    <Box key={index} className={classes.container}>
                        <Box className={classes.headingContainer}>
                            <Typography variant="h2-medium" className={classes.title}>
                                {step.title}
                            </Typography>
                            <Typography variant="h6-medium" className={classes.about} >
                                {step.about}
                            </Typography>
                        </Box>
                        <Box
                            className={`${classes.mainContainer} 
                            ${step.reverse ? classes.videoContainerReverse : ""}`}
                        >
                            <Box className={classes.contentContainer}>

                                {
                                    step.discriptions.map((discription, index) => (
                                        <Box key={index} className={classes.description}>
                                            <img
                                                src={`${process.env.REACT_APP_ASSETS_URL}/images/t&t_green_check.svg`}
                                                alt="icon"
                                            />
                                            <Typography variant="h6-regular"
                                            >
                                                {discription}
                                            </Typography>
                                        </Box>
                                    ))
                                }
                            </Box>
                            <Box
                                className={`${classes.videoContainer} 
                                ${step.reverse ? "" : classes.videoContainerReverse}`}
                            >
                                <img
                                    src={step.video}
                                    className={classes.video}
                                    alt="gifs"
                                />
                            </Box>
                        </Box>
                        <Box className={classes.tryButton}>
                            <HoverButton
                                label="Try our AI"
                                onClick={onExperienceLive}
                                icon={`${process.env.REACT_APP_ASSETS_URL}/images/home_stars_icon.svg`}
                            />
                        </Box>
                        <img
                            className={classes.background}
                            src={`${process.env.REACT_APP_ASSETS_URL}/images/t%26t_wave_1.png`}
                            alt="background"
                        />
                    </Box>
                ))
            }
            <Typography className={`${classes.gradientText} ${classes.bottomText} 
            ${classes.displayNone}`} variant="h5-regular">
                Using our AI-powered training and assessment solutions, we have seen a 40%
                improvement in learning outcomes for our clients
            </Typography>
            <a
                target='_blank'
                rel='noreferrer'
                href={process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL}
                className={classes.textDecoration} >
                <IconButton
                    label="Connect with us"
                    icon={`${process.env.REACT_APP_ASSETS_URL}/images/home_chat_icon.svg`}
                    color='primary.main'
                    variant="outlined"
                    width="184px"
                    onClick={handleLinkClick}
                />
            </a>
        </Box>
    );
}
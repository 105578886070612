import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { SlidingArrowButton } from 'components/CustomButton';

const useStyles = makeStyles((theme) => ({
    container : {
        padding: theme.spacing(0, 37.5),
        gap : theme.spacing(15),
        marginTop : theme.spacing(2),
        width : "100%",
        display : 'flex',
        justifyContent:'center',
        alignItems:'center',
        position : 'relative',
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(0, 15),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(10, 10),
          flexWrap : 'wrap',
          gap : theme.spacing(2)
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(10, 5),
        },
    },
    boxContainer : {
        display : 'flex',
        gap : theme.spacing(7),
        flexDirection : 'column'
    },
    personImage : {
        position : 'absolute',
        bottom : 0,
        left : '37%',
        [theme.breakpoints.down("lg")]: {
            display : 'none'
          },
    },
    coffeeImage : {
        width : '420px',
        height : '420px',
        [theme.breakpoints.down("MD")]: {
            width : '320px',
            height : '320px',
          },
        [theme.breakpoints.down("sm")]: {
            width : '320px',
            height : '320px',
          },
    }
}))

const PrepareInterview = () => {
    const classes = useStyles();
  return (
    <Box className={classes.container}>
        <Box className={classes.boxContainer}>
            <Box display='flex' flexDirection='column' gap='12px'>
                <Typography variant='h2-bold' color='neutral.clr-700'>
                    Prepare for an interview in less than a cup of cappuccino!
                </Typography>
                <Typography variant='body01-bold' color='neutral.clr-600'>
                     Start Preparing for the Interviews & practice unlimited times!
                </Typography>
            </Box>
            <SlidingArrowButton
                label="Get Interview Wizard Now!"
                bgColor='#1961BF'
                variant="outlined"
                width="343px"
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  const element = document.getElementById("pricing");
                  if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                  }
              }}
            />
        </Box>
        <img 
          src='https://assets.languify.in/images/coffee.svg' 
          alt='img' 
          className={classes.coffeeImage}
        />
        <img 
          src='https://assets.languify.in/images/person_2_b2c.svg' 
          alt='img' 
          className={classes.personImage}
        />
    </Box>
  )
}

export default PrepareInterview
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(15, 37.5),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(12),
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        opacity: 1,
        background: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), 
                  url('https://assets.languify.in/images/b2cFrame.png') `,
        [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(15, 37.5),
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(15),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(7, 10),
            gap: theme.spacing(7),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(7, 5),
        },
    },
    containerVisible: {
        opacity: 1,
        transform: 'translateY(0)',
    },
    typo: {
        textAlign: 'center',
        ...theme.typography['h2-bold'],
    },
    accordion: {
        border: '2px solid',
        borderColor: theme.palette.neutral['clr-300'],
        borderRadius: '8px',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        overflow: 'hidden',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    accordionExpanded: {
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    accordionSummary: {
        backgroundColor: theme.palette.background.default,
        height: '68px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px 4px 0 0',
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            width: '36px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'transform 0.5s ease-in-out',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '36px',
            transition: 'transform 0.5s ease-in-out',
        },
    },
    accordionSummaryExpanded: {
        '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotateX(180deg)',
        },
    },
    accordionDetails: {
        backgroundColor: theme.palette.background.paper,
        overflow: 'hidden',
        borderTop: 'none',
        paddingTop: 0,
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(10),
        transition: 'opacity 0.3s ease-in-out',
        opacity: 1,
    },
    typography: {
        ...theme.typography['h6-medium'],
        color: "#2D3142",
    },
    typographyVisible: {
        opacity: 1,
        transform: 'translateY(0)',
    },
}));

const faqs = [
    {
        question: "How will I get access to the platform?",
        answer: `Click the 'Try for Free' Button and Fill Out the Form with Valid Details. You'll Receive an Email with Your Credentials and Practice Link right away`
    },
    {
        question: "How will this help me improve?",
        answer: `Interview Wizard provides you 24/7 interview practice so you can practice thoroughly and perform confidently in your upcoming interviews.`,
    },
    {
        question: "Will I be able to practice HR questions?",
        answer: `Yes, you’ll be able to practice personal background, behavioural, leadership, and other HR questions.`,
    },
    {
        question: "Will this provide me with interviews with companies ?",
        answer: `Yes, we will be posting job vacancies in the student WA community.`,
    },
    {
        question: "What if I have any queries or doubts while using the tool?",
        answer: `Once you purchase a subscription, you'll be added to an exclusive WhatsApp community where you can reach out to our team for assistance at any time.`,
    },
    {
        question: "Can I access the platform on my mobile?",
        answer: `No, Interview Wizard is designed to resemble a real interview and is only accessible on a laptop. It will not work on mobile devices.`,
    },
    {
        question: "Is the feedback personalized?",
        answer: `Yes, the feedback is personalized for each mock interview attempt based on your performance, articulation, and alignment.`,
    },
    {
        question: "What if I want to practice my own interview questions?",
        answer: `In case you have a set of interview questions that you really need to practice, you can mail us the questions at growth@languify.in and our team will set it up within 24 hours.`,
    },
    {
        question: "Is it better than a real interviewer?",
        answer: `Interview Wizard offers the unique benefit of 24/7 availability, unlimited attempts, and detailed feedback for every single question. Unlike human interviewers, it allows you to practice tailored interview questions for as many companies as you want, ensuring you are fully prepared for every opportunity that comes your way.`
    },
];

export default function Faq() {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const sectionRef = useRef(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box
            className={`${classes.container}`}
            ref={sectionRef}
            id='faqs'
        >
            <Typography className={classes.typo} color='b2c.dark-900'>
                Frequently Asked Questions
            </Typography>
            <Box>
                {faqs.map((faq, index) => (
                    <Accordion
                        className={`${classes.accordion} ${expanded === index ?
                            classes.accordionExpanded : ''}`}
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        TransitionProps={{ timeout: 500 }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            className={`${classes.accordionSummary} ${expanded === index ?
                                classes.accordionSummaryExpanded : ''}`}
                        >
                            <Typography
                                variant='h6-bold'
                                color='b2c.dark-900'
                                className={`${classes.summaryTypography} ${expanded === index ?
                                    'expanded' : 'collapsed'}`}
                            >
                                {index + 1}.&nbsp;
                            </Typography>
                            <Typography
                                variant='h6-bold'
                                color='b2c.dark-900'
                                className={`${classes.summaryTypography} ${expanded === index ?
                                    'expanded' : 'collapsed'}`}
                            >
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography className={`${classes.typography} ${expanded === index ?
                                classes.typographyVisible : ''}`}>
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
}

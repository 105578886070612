import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import CustomButton from "components/CustomButton";

import { IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 30,
        gap: 20,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});


export default function PurchaseSuccessModal({ open, onClose, plan }) {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
            fullWidth
            keepMounted
            PaperProps={{ sx: { borderRadius: '18px' } }}
        >
            <DialogContent>

                <Box className={classes.container}>
                    <img src="https://assets.languify.in/images/success-tick.svg"
                        alt="success"
                        height={60}
                    />

                    <Typography variant="h4-medium" color='success.clr-700'>
                        {
                            plan === "trial" ?
                                "Account created successfully!" :
                                "Payment successfull!"
                        }
                    </Typography>

                    <Typography variant="body01-bold" color='neutral.clr-700'>
                        We have sent the login credentials on your registered email
                    </Typography>

                    <CustomButton fullWidth variant="contained"
                        onClick={() => window.open("https://mail.google.com", "_blank")}
                    >
                        Open Gmail
                    </CustomButton>

                </Box>
                <IconButton
                    onClick={onClose}
                    style={{ position: "absolute", top: 6, right: 10 }}
                >
                    <Close />
                </IconButton>
            </DialogContent>
        </Dialog>
    );
}